import React from 'react';
import { Row, Col, Container } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { MailService } from '../services/mail-services';
AOS.init();
const mailService = new MailService();
const Footer = () => {

    const handleSave = async () => {
        var username = document.getElementById("Name").value;
        var useremail = document.getElementById("Email").value;
        var userphone = document.getElementById("Phone").value;
        var usermessage = document.getElementById("Message").value;
        var validateemail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        var validatephone = /^(\+\d{1,3}[- ]?)?\d{10}$/;
        if (username === null || username === "") {
            alert("User name can't be empty.");
            return;
        }
        else if (useremail === null || useremail === "") {
            alert("Email can't be empty.");
            return;
        }
        else if (!useremail.match(validateemail)) {
            alert("Email should be in correct format as 'xyz@abc.com/in/org'.");
            return;
        }
        else if (userphone === null || userphone === "") {
            alert("User phone number can't be empty.");
            return;
        }
        else if (!userphone.match(validatephone)) {
            alert("User phone Number contains only 10 digit numbers.");
            return;
        }
        else if (usermessage === null || usermessage === "") {
            alert("Message can't be empty.");
            return;
        }
        else {
            let dataEmail = {
                To: 'info@thelabcanton.com',
                From: useremail,
                Subject: "User Request for Labcanton",
                Usermessage: usermessage,
                Username: username,
                Userphone: userphone
            };
            const data = await mailService.sendMail(dataEmail).then(response => {
                if (response.ok) {
                    alert("Mail sent successfully.");
                    (document.getElementById("Message")).value = '';
                    (document.getElementById("Email")).value = '';
                    (document.getElementById("Name")).value = '';
                    (document.getElementById("Phone")).value = '';
                }
                else {
                    alert("Mail failed.")
                }
            });
        }
    };
    return (
        <div>
            <div className="details-main-div  text-black" id="conatctus">
                <Container>
                    <Row className="padding-top-5">
                        <Col md={4} className="padding-top-5">
                            <div className="send-inquiry-div">
                                <h4 className="ml-2 text-white">GET IN TOUCH</h4>
                                <input className="details-textbox font-14" id="Name" type="text" placeholder="Your name"></input>
                                <input className="details-textbox font-14" id="Email" type="text" placeholder="Email address"></input>
                                <input className="details-textbox font-14" id="Phone" type="number" placeholder="Phone number"></input>
                                <textarea className="details-textbox font-14" id="Message" type="text" placeholder="Your message"></textarea>
                                <br />
                                <button className="btn-send-msg font-14" onClick={handleSave}>Send Inquiry</button>
                            </div>
                        </Col>
                        <Col md={4} className="padding-top-5">
                            <p><i className="fa fa-phone mr-2 color-039be5" aria-hidden="true"></i><a link href="/" className="a-link">734-330-3860</a></p>
                            <p><i className="fa fa-envelope mr-2 color-039be5" aria-hidden="true"></i><a link href="mailto:info@thelabcanton.com" className="a-link">info@thelabcanton.com</a></p>
                            <p><i className="fa fa-list-alt mr-2 color-039be5" aria-hidden="true"></i><a link href="https://thelabcanton.com/" className="a-link">https://thelabcanton.com/</a></p>
                            <p> <i className="fa fa-location-arrow mr-2 color-039be5" aria-hidden="true"></i><a link href="https://maps.google.com/maps?q=46615,Michigan Avenue,Canton, MI, 48188" className="a-link">46615 Michigan Avenue Canton, MI, 48188</a></p>
                        </Col>
                        <Col md={4} className="padding-top-5">
                            <p className="text-uppercase color-cadetblue">WORKING HOURS</p>
                            <p>Monday: 04:00PM - 09:00PM</p>
                            <p>Tuesday: 03:00PM - 05:00PM</p>
                            <p>Wednesday: 04:00PM - 09:00PM</p>
                            <p>Thursday: 03:00PM - 05:00PM</p>
                            <p>Friday: 04:00PM - 09:00PM</p>
                            <p>Saturday: 12:30PM - 09:00PM</p>
                            <p>Sunday: 01:00PM - 05:00PM</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={7} className="padding-top-5"></Col>
                        <Col md={5} className="padding-top-5">
                            <div className="float-right">
                                <span className="menu-btn-grid"><a link className="menu-btn" href="/">Home</a></span>
                                <span className="menu-btn-grid"><a link className="menu-btn" href="/">Offers</a></span>
                                <span className="menu-btn-grid"><a link className="menu-btn" href="/#ourservices">Services</a></span>
                                <span className="menu-btn-grid"><a link className="menu-btn" href="/#gallery">Gallery</a></span>
                                <span className="menu-btn-grid"><a link className="menu-btn" href="/#testimonials">Testimonials</a></span>
                                <span className="menu-btn-grid"><a link className="menu-btn" href="/#conatctus">Contact Us</a></span>
                            </div>
                        </Col>
                        <Col md={4}></Col>
                        <Col md={8}><span><a href="https://www.referrizer.com/?aid=XNI5550&utm_source=Frontend%20Link&utm_medium=Landing%20Page%20Footer&utm_campaign=Referral%20Marketing" target="_blank"><span className="baseball-a"> Own a Business? Start your Marketing Automation for FREE</span></a></span></Col>
                    </Row>
                    <Row className="pt-3">
                        <Col md={10}></Col>
                        <Col md={2}>
                            <Row className="text-white">
                                <Col md={4} xs={2}><i className="fa fa-facebook  fa-2x" aria-hidden="true"></i></Col>
                                <Col md={4} xs={2}><i className="fa fa-twitter  fa-2x" aria-hidden="true"></i></Col>
                                <Col md={4} xs={2}><i className="fa fa-instagram  fa-2x" aria-hidden="true"></i></Col>
                            </Row>
                        </Col>
                        <Col md={12} className="pt-3">
                            <p className="text-right mb-0 baseball-p" >All rights reserved © Referrizer and The Lab. 2020</p>
                            <p className="text-right baseball-p">Powered By <a link href="https://www.zorroapps.com/" target="_blank" className="a-link-black">ZorroApps</a></p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}
export default Footer