import { HttpService } from './http.services';
export class MailService extends HttpService {

	sendMail = (model: any) => (
		fetch('http://www.thelabcanton.com/labCantonMailApi/api/Mail/Post', {
			method: 'POST',
			headers: {
                'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				To: model.To,
				From:model.From ,
				Subject: model.Subject,
				Username: model.Username,
				Usermessage: model.Usermessage,
				Userphone:model.Userphone
			}
			)
		}).then(async response => {
			const data = await response;

			// check for error response
			if (!response.ok) {
				// get error message from body or default to response status
				const error = (data) || response.status;
				return Promise.reject(error);
			}
			return data;
		})
			.catch(error => {
				return error;
			})

	)
}


