import axios, { AxiosRequestConfig } from 'axios';

const instance = axios.create({
	baseURL: process.env.REACT_APP_AXIOS_BASE_URL,
});

instance.defaults.baseURL = process.env.REACT_APP_AXIOS_BASE_URL;
instance.defaults.withCredentials = true;
instance.interceptors.request.use(
	(config: AxiosRequestConfig): AxiosRequestConfig => {
		const userString = localStorage.getItem('user');
		let user;
		if (userString) {
			user = JSON.parse(userString);
		}
		if (user && user.userDetails && user.userDetails.token) {
			config.headers.common.Authorization = `Bearer ${user.userDetails.token}`;
		}
		return config;
	}
);
export default instance;
